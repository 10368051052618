import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var bookStock = function (user) {
    var hasPermissionCreateBookStock = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_STOCK, MethodCode.CREATE);
    var hasPermissionListBookStock = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_STOCK, MethodCode.LIST);
    var hasPermissionViewBookStock = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_STOCK, MethodCode.VIEW);
    var hasPermissionDeleteBookStock = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_STOCK, MethodCode.DELETE);
    var hasPermissionEditBookStock = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_STOCK, MethodCode.EDIT);
    return {
        hasPermissionEditBookStock: hasPermissionEditBookStock,
        hasPermissionCreateBookStock: hasPermissionCreateBookStock,
        hasPermissionListBookStock: hasPermissionListBookStock,
        hasPermissionViewBookStock: hasPermissionViewBookStock,
        hasPermissionDeleteBookStock: hasPermissionDeleteBookStock,
    };
};
export default bookStock;
